/* eslint-disable */
import React,{ useEffect,useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { generateText } from 'src/api/ai';
import { Dropdown } from 'semantic-ui-react';

//2-5-24 added showMenu=true to support not showing menu's in situations when 
//using control in form from datalist to create new record. In those use-cases, the toolbar menus don't work.

const CustomEditor = ({field, initValue, updateContent,uitype,height,callingComponent,showMenu=true,tokens}) => {
  
  const editorRef = useRef(null);
  let editor;

  const handleEditorInit = (evt, editorInstance) => {
    editor = editorInstance;
     editorRef.current = editorInstance;
    if(callingComponent==='EmailControl') {
      focusEditor();
    }
  };

  const focusEditor = () => {
    if (editor) {
      editor.focus();
    }
  };

  const handleChange = (value) => {
    if (editorRef.current) {
      if(value !=='') {
       editorRef.current.insertContent(`{{${value}}}`);
      }
    }
  }


	let plugins ='advlist anchor autolink autoresize code charmap  directionality  emoticons fullscreen help image importcss insertdatetime link  lists media  nonbreaking pagebreak preview quickbars save searchreplace table visualblocks visualchars wordcount'
	let toolbars ='undo redo | formatselect | bold italic backcolor | \
	alignleft aligncenter alignright alignjustify | \
	link | bullist numlist outdent indent | removeformat | customAIButton'
	if(callingComponent==='form') {
		plugins ='advlist anchor autolink autoresize code charmap  directionality  emoticons fullscreen help image importcss insertdatetime link  lists media  nonbreaking pagebreak preview  save searchreplace table visualblocks visualchars wordcount'
		toolbars = showMenu ? 'undo redo | formatselect | bold italic backcolor | \
			alignleft aligncenter alignright alignjustify | \
			link | bullist numlist outdent indent | removeformat' :  'bold italic'
	} 


	return (<>
           {tokens !==null && tokens !==undefined ? (
            <div style={{margin:'5px'}}>
            TOKENS: <Dropdown search clearable defaultValue='' style={{width:'300px'}} fluid selection options={tokens} onChange={(e,data)=>handleChange(data.value)} />
           </div>):null}
            
		        <Editor
                  onInit={handleEditorInit}
                  initialValue={initValue ?? '<div></div>'}
                  init={{
                    removed_menuitems: "newdocument",
                    convert_urls:false,
                    min_height: height !==undefined ? height : null,
                    max_height: height !==undefined ? height : null,
                    menubar: showMenu,
                    statusbar: false,
                    promotion: false,
                    branding: false,
                    plugins: plugins,
                    toolbar: toolbars,
                    content_style: "@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Bungee+Shade&family=Cabin+Sketch:wght@400;700&family=Cabin:ital,wght@0,400..700;1,400..700&family=Caveat:wght@400..700&family=Charm:wght@400;700&family=Creepster&family=Heebo:wght@100..900&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Indie+Flower&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Miniver&family=Monda:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Moo+Lah+Lah&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Rampart+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Dirt&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap)",
                    font_family_formats: "Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Bitter=Bitter;Book Antiqua=book antiqua,palatino;Bungee Shade=Bungee Shade;Cabin=Cabin;Caveat=Caveat;Charm=Charm;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Creepster=Creepster;Georgia=georgia,palatino;Heebo=Heebo;Helvetica=helvetica;IBM Plex Sans=IBM Plex Sans;Impact=impact,chicago;Indie Flower=Indie Flower;Josefin Sans=Josefin Sans;Lato=Lato;Merriweather=Merriweather;Miniver=Miniver;Monda=Monda;Montserrat=Montserrat;Moo Lah Lah=Moo Lah Lah;Noto Sans=Noto Sans;Nunito=Nunito;Open Sans=Open Sans;Oswald=Oswald;Playfair Display=Playfair Display;Poppins=Poppins;PT Sans=PT Sans;Quicksand=Quicksand;Raleway=Raleway;Rampart One=Rampart One;Roboto=Roboto;Rubik Dirt=Rubik Dirt;Source Code Pro=Source Code Pro;Source Sans 3=Source Sans 3;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Ubuntu=Ubuntu;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;Work Sans=Work Sans;",
                    quickbars_selection_toolbar: 'bold italic | h1 h2 h3 h4 formatselect | quicklink',
                  setup: function (editor) {
                    // Add a custom button to the toolbar
                    editor.ui.registry.addButton('customAIButton', {
                      text: 'AI', // Button label
                      onAction: async function () {
                        // Custom action when the button is clicked
                        let content = editor.getContent();
                        const lastIdx = content.lastIndexOf('</p>');
                        const finalString = content.slice(0, lastIdx) + '. return in html' + content.slice(lastIdx);
                     
                        // Do your processing on the content here
                        // For example, let's add "Processed:" before the content
                        const processedContent = await generateText(finalString,false)
                        editor.setContent(processedContent);
                      },
                    });
                  },
                  }}
                  onEditorChange={(val)=> ['RTFElement','EmailControl','Block'].includes(callingComponent) ? updateContent(val) : updateContent(field,val,uitype)}
                />

	</>);
}


export default CustomEditor